// only bootstrap grid included 5.1
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// css variables used to avoid imports of sass variables into each components
:root {
  // colors
  --primary-color: #6ECB44;
  --heading-color: #252525;
  --danger-color: red;
  --content-color: #333;
  --light-color: #fff;
  --site-bg: #f5f4f7;
  
  --input-text-color: #252525;
  --label-color: var(--primary-color);
  --link-color: var(--primary-color);
  --mat-option-selected-state-label-text-color: red !important;
  // sizes
  --font-family: 'Montserrat', sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mdc-typography-body1-font-family: var(--font-family);
  --mdc-typography-font-family: var(--font-family);
  --font-size: 1.125rem; //18px
  --font-size--nav: 1rem; //16px
  --font-size--table: 0.875rem;
  --font-weight: 400;
  --font-weight--semibold: 500;
  --font-weight--bold: 600;
  --line-height: 1.66;
  --line-height--heading: 1.125;
  --h1-size: 2.5rem; // 80px
  --h2-size: 3.125rem; // 50px
  --h3-size: 1.75rem; // 28px
  --mat-expansion-header-text-font:var(--font-family);
  --mat-form-field-container-text-font:var(--font-family);
  --mat-tab-header-label-text-font:var(--font-family) !important; 
  --mat-expansion-container-text-font:var(--font-family); 
  // others
  --btn-padding: 20px 30px;
  --heading-margin: 0 0 20px 0;
  --border-radius: 0.625rem; // 10px
  --border-radius--outer: 1.25rem; // 20px
  --border-radius--pills: 4.375rem; // 70px
  --box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  --mat-option-selected-state-label-text-color: #6ECB44 !important;
  --mdc-filled-text-field-label-text-color:#6A6A6A;
  --mdc-filled-text-field-input-text-color:#333;
  --mdc-checkbox-selected-icon-color : #6ECB44 !important;
  --mdc-checkbox-selected-hover-icon-color: #6ECB44 !important;
  --mdc-checkbox-selected-checkmark-color : #fff!important;

  // icons
  --select-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none"><path d="M10.2207 0.975613L5.92144 6L1.0007 0.975613" stroke="%237D7D7D" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  --calendar-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M13.6471 1.2549H11.6078V0.470588C11.6078 0.34578 11.5583 0.226084 11.47 0.137832C11.3818 0.0495796 11.2621 0 11.1373 0C11.0124 0 10.8927 0.0495796 10.8045 0.137832C10.7162 0.226084 10.6667 0.34578 10.6667 0.470588V1.2549H4.07843V0.470588C4.07843 0.34578 4.02885 0.226084 3.9406 0.137832C3.85235 0.0495796 3.73265 0 3.60784 0C3.48304 0 3.36334 0.0495796 3.27509 0.137832C3.18683 0.226084 3.13725 0.34578 3.13725 0.470588V1.2549H1.09804C0.806921 1.25523 0.52782 1.37102 0.321968 1.57687C0.116117 1.78272 0.000326334 2.06182 0 2.35294V14.902C0.000326334 15.1931 0.116117 15.4722 0.321968 15.678C0.52782 15.8839 0.806921 15.9997 1.09804 16H13.6471C13.9382 15.9997 14.2173 15.8839 14.4231 15.678C14.629 15.4722 14.7448 15.1931 14.7451 14.902V2.35294C14.7448 2.06182 14.629 1.78272 14.4231 1.57687C14.2173 1.37102 13.9382 1.25523 13.6471 1.2549ZM1.09804 2.19608H3.13725V2.98039C3.13725 3.1052 3.18683 3.2249 3.27509 3.31315C3.36334 3.4014 3.48304 3.45098 3.60784 3.45098C3.73265 3.45098 3.85235 3.4014 3.9406 3.31315C4.02885 3.2249 4.07843 3.1052 4.07843 2.98039V2.19608H10.6667V2.98039C10.6667 3.1052 10.7162 3.2249 10.8045 3.31315C10.8927 3.4014 11.0124 3.45098 11.1373 3.45098C11.2621 3.45098 11.3818 3.4014 11.47 3.31315C11.5583 3.2249 11.6078 3.1052 11.6078 2.98039V2.19608H13.6471C13.6886 2.19613 13.7285 2.21268 13.7579 2.24208C13.7873 2.27149 13.8039 2.31136 13.8039 2.35294V5.01961H0.941176V2.35294C0.94123 2.31136 0.957774 2.27149 0.98718 2.24208C1.01659 2.21268 1.05645 2.19613 1.09804 2.19608ZM13.6471 15.0588H1.09804C1.05645 15.0588 1.01659 15.0422 0.98718 15.0128C0.957774 14.9834 0.94123 14.9435 0.941176 14.902V5.96078H13.8039V14.902C13.8039 14.9435 13.7873 14.9834 13.7579 15.0128C13.7285 15.0422 13.6886 15.0588 13.6471 15.0588Z" fill="%23979797"/></svg>') no-repeat  center !important;
  --tooltip-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/></svg>');
}


@import 'assets/scss/common';
@import 'assets/scss/grid';
@import 'assets/scss/breakpoints';
@import 'assets/scss/typography';
@import 'assets/scss/elements/form-elements';
@import 'assets/scss/modal';
@import 'assets/scss/mat-customised';
@import 'assets/scss/filter';
@import 'assets/scss/pagination';


.cdk-overlay-container{
      .mat-mdc-select-panel-above div.mat-mdc-select-panel mat-option.sort-option {
        color: #333;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        min-height: 28px;
    }
    .mat-mdc-menu-panel::-webkit-scrollbar-thumb
    {
        background-color:#ACACAC;
        width: 5px;
    }
    .mat-mdc-menu-panel::-webkit-scrollbar {
      width: 5px;
      background: #EFEFEF;
     }
   .notification-name {
      display: block;
      color: #333;
      text-align: left;
      font-family:  var(--font-family);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.16px;
  }
  .notification-description{
    display: block;
    color: #333;
    text-align: left;
    font-family:  var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.16px;
  }
  .mat-mdc-menu-item{
    min-height: 40px;
  }
}
.mdc-data-table__cell {
  font-family:  var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.mat-mdc-table {
  background: inherit !important;
}

.mdc-data-table__header-cell {
  font-family:  var(--font-family);
  font-size: 14px;
  font-style: normal;
  color: #333;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.eye-icon {
  background: url(assets/images/eye-icon.svg) no-repeat center;
  width: 22.96px;
  height: 17px;

  padding: 0;
}

.eye-icon.active{
  background: url(assets/images/active-eye-icon.svg) no-repeat center;
  width: 22.96px;
  height: 14px;
}
.cdk-overlay-transparent-backdrop {
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

table tr .mdc-data-table__cell:first-child,
.mdc-data-table__header-cell:first-child {
  padding-left: 0;
}

table tr .mdc-data-table__cell:last-child,
.mdc-data-table__header-cell:last-child {
  padding-right: 0;
}

.vendor-listing-wrap table {
  padding-inline: 30px;
}
.mat-mdc-cell{
  padding-block: 5px;
}
.mat-expansion-panel-header{
  height: auto !important;
  min-height: 48px;
}
.mat-mdc-cell a {
  font-size: inherit;
  border-radius: 60px;
  border: 0.5px solid #767676;
  padding: 4px 12px;
  line-height: normal;
}

.product-detail {
  &-body-wrap {
    .mat-expansion-indicator::after {
      margin-inline: 3px;
    }

    .mat-expansion-panel-body {
      padding-left: 0;
    }
  }

}

.mat-typography p {
  margin: 0;
}
.pac-container.pac-logo{
    padding: 10px 0px 20px 0px;
    border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-top: 8px;
    border-top: none;
  
}
.pac-logo:after{
  display: none !important;
}
 .pac-icon{
  margin-top: 2px;
  margin-right: 10px;
}
.pac-item:first-child {
  border-top: none;
}
.pac-item:last-child {
  border-bottom: 1px solid #E4E6EA;
}
.pac-item {
  display: flex;
  align-items: center;
  padding-block: 6px;
  padding-inline: 15px;
  border: 1px solid #E4E6EA;
  cursor: pointer;
}
.pac-item-query,
.pac-item{
  font-family:  var(--font-family);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #333;
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
  padding: 0;
}
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error{
    font-family:  var(--font-family);
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    padding-block: 0;
    color: #dc3545;
}
@media screen and (min-width : 960px) and  (max-width : 1024px) {
  .mdc-data-table__cell{
      font-size: 12px;
  }
  .mdc-data-table__header-cell{
      font-size: 12px;
  }
}

@media screen and (max-width: 992px)  {

  .vendor-request-list-wrap {
  
  table tr .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding-left: 16px;
  }
  table tr .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding-right: 16px;
    display: flex;
    align-items: center;
  }

  table tr .mdc-data-table__cell:last-child {
    .btn.btn_outline {
      line-height: 20px;
    }
  }

  .mat-mdc-table {
    border: 0;
    vertical-align: middle;
    background-color: inherit;
  }

  .mat-mdc-table .mdc-data-table__header-row {
    display: none;
  }


  .mat-mdc-table .mdc-data-table__row {
    border-radius: 14px;
    border: 3px solid #FFF;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(177, 197, 224, 0.10);
    margin: 10px 0px;
    padding: 10px 0;
  }

  .mat-mdc-table caption {
    font-size: 1em;
  }

  .mat-mdc-table .mat-mdc-header-cell {

    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .mat-mdc-row {
    border-bottom: 5px solid #ddd;
    display: block;
    height: unset !important;
  }

  .mat-mdc-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    height: 30px;
    margin-bottom: 0;
    margin-inline: -1px;

  }

  .mdc-data-table__cell {
    font-weight: 400;
    margin-block: 10px;
    font-family:  var(--font-family);
  }

  .mat-mdc-cell a {
    font-size: inherit;
    border-radius: 60px;
    border: 0.5px solid #767676;
    padding: 4px 12px;
    line-height: normal;
  }

  .mat-mdc-cell:before {

    content: attr(data-label);
    float: left;
    font-weight: 500;

  }

  .mat-mdc-cell:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    margin-bottom: 10px;
  }

  .mat-mdc-cell:first-child {
    margin-top: 10px;
  }

  .mdc-data-table__cell {
    display: flex;
    justify-content: space-between;
  }

  .mdc-data-table__cell {
    font-size: 12px;
  }

  .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: 1px solid #ddd !important;
  }

  .mat-mdc-table .mat-mdc-row:hover,
  .mat-mdc-table .mat-mdc-footer-row:hover {
    background-color: #fff !important;
  }

  }
}

@media screen and (max-width: 960px) {

  table tr .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding-left: 16px;
  }

  .vendor-listing-wrap table {
    padding-inline: 0;
  }

  table tr .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding-right: 16px;
    display: flex;
    align-items: center;
  }

  table tr .mdc-data-table__cell:last-child {
    .btn.btn_outline {
      line-height: 20px;
    }
  }

  .mat-mdc-table {
    border: 0;
    vertical-align: middle;
    background-color: inherit;
  }

  .mat-mdc-table .mdc-data-table__header-row {
    display: none;
  }


  .mat-mdc-table .mdc-data-table__row {
    border-radius: 14px;
    border: 3px solid #FFF;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(177, 197, 224, 0.10);
    margin: 10px 0px;
    padding: 10px 0;
  }

  .mat-mdc-table caption {
    font-size: 1em;
  }

  //  Enable this to hide header
  .mat-mdc-table .mat-mdc-header-cell {

    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .mat-mdc-row {
    border-bottom: 5px solid #ddd;
    display: block;
    height: unset !important;
  }

  .mat-mdc-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    min-height: 30px;
    margin-bottom: 0;
    margin-inline: -1px;

  }

  .mdc-data-table__cell {
    font-weight: 400;
    margin-block: 10px;
    font-family:  var(--font-family);
  }

  .mat-mdc-cell a {
    font-size: inherit;
    border-radius: 60px;
    border: 0.5px solid #767676;
    padding: 4px 12px;
    line-height: normal;
  }

  .mat-mdc-cell:before {

    content: attr(data-label);
    float: left;
    font-weight: 500;

  }

  .mat-mdc-cell:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    margin-bottom: 10px;
  }

  .mat-mdc-cell:first-child {
    margin-top: 10px;
  }

  .mdc-data-table__cell {
    display: flex;
    justify-content: space-between;
  }

  .mdc-data-table__cell {
    font-size: 12px;
  }

  .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: 1px solid #ddd !important;
  }

  .mat-mdc-table .mat-mdc-row:hover,
  .mat-mdc-table .mat-mdc-footer-row:hover {
    background-color: #fff !important;
  }

  .vendor-payout {
    &-search-filter mat-form-field .mat-mdc-form-field-icon-suffix {
      padding-right: 14px;
    }
  }

  .vendor-new-orders {
    &-wrap {
      .mat-mdc-cell[data-label="Action"] {
        height: 60px;
        display: flex;
        align-items: center;
      }

      .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
        border-bottom: none !important;
      }
    }
  }

  .vendor-order-awaiting {
    &-wrap {
      .mat-mdc-cell[data-label="Rider Info"] {
        height: 64px;
        display: flex;
        align-items: center;
      }

      .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
        border-bottom: none !important;
      }
    }

  }
  .change-logs-request-wrap,
  .detail-logs-modal,
  .order-request-detail-wrap {
    .mat-mdc-row {
      display: table-row;
    }

    .mdc-data-table__cell {
      display: table-cell;

    }

    .mat-mdc-table .mat-mdc-header-cell {
      border: none;
      border-bottom: 1px solid #ccc;
      clip: unset;
      height: auto;
      margin: 0px;
      padding: 0 16px 0 16px;
      position: relative;
      width: auto;
    }

    .mat-mdc-table .mdc-data-table__header-row {
      display: table-row;
    }

    tfoot td {
      font-size: 12px;
      line-height: 14px;
    }
  }

}

@media screen and (max-width:492px) {
  .order-request-detail-wrap {

    .mdc-data-table__cell,
    .mat-mdc-table .mat-mdc-header-cell {
      padding: 0 6px 0 6px;
    }

    .mdc-data-table__header-cell {
      font-size: 12px;
    }

    .order-no-recieved-wrap {
      font-size: 14px;
      line-height: 18px;

      .order-recieved-time {
        font-size: 11px;
      }
    }

    tfoot td {
      font-size: 10px;
      line-height: 14px;
    }

    tfoot .mat-mdc-footer-row {
      height: 35px;
    }
  }
}



.order-request-in-modal .order-request-detail-wrap {
  padding: 1px;
}

.loadingRevenueData thead tr {
  opacity: 0;
  visibility: hidden;
}


.order_id{
  cursor: pointer;
}
.change-logs-request-wrap{
  .mat-mdc-tab-body-content{
    overflow: hidden;
  }
}

.mat-content.mat-content-hide-toggle {
  margin-right: 0px !important;
}



.body_content{
  margin-bottom: 70px;
}



@media screen and (max-width:960px) {
  .dashboard-main-wrap_admin{
    .mdc-data-table__cell{
      text-align: right !important;
      padding-bottom: 10px !important;
      &::before{
        text-align: left;
      }
    }
  }
  hr{
    display: none;
  }
}

.activeMerchantList .mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.activeMerchantList .mat-mdc-form-field {
  width: 90%;
}

.merchantSpinner .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: white !important;
}

.disableMatFieldByClass .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input{
  color: #00000061;
}

.disableMatFieldByClass .customised-form *:not(.customised-form-heading){
  background-color: #fafafa;
}

.liveOrder .mat-mdc-paginator-page-size {
  display: none;
}