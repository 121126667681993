/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
	color: var(--content-color);
	font-family: var(--font-family) !important;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
}
.lead{
	margin-bottom: 0 !important;
}
.sub-lead{
	font-size: 0.9375rem;
	color: rgba(86, 86, 86, 1);
	font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
	margin: var(--heading-margin);
	font-weight: var(--font-weight--bold);
	color: var(--heading-color);
	line-height:var(--line-height--heading);
}
h1{
	font-size: calc(calc(var(--h1-size) / 2 ) + 1.5vw);
}
h2{
	font-size: calc(calc(var(--h2-size) / 2 ) + 1vw);
}
h3{
	font-size: calc(var(--h3-size) / 1.125);
}
h4{
	font-size: 1.275rem;
}
h5{
	font-size: 1.25rem;
}

h6{
	font-size: 1rem;
}
p {
	margin-top: 0;
	line-height: 2;
	&:not(:last-child){
		margin-bottom: 15px;
	}
}

dfn, cite, em, i {
	font-style: italic;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 15px;
	font-size: 0.9375rem;
}

abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark, ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}
.text-danger{
	color: var(--danger-color);
}


@include up-xl{
	h1{
		font-size: var(--h1-size);
	}
	h2{
		font-size: var(--h2-size);
	}
	h3{
		font-size: var(--h3-size);
	}
	h4{
		font-size: 1.125rem;
	}
	h4{
		font-size: 1rem;
	}
}