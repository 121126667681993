.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--primary-color) !important;
}

.loader_parent {
    &:not(.loader_parent--hide) {
        position: relative;
        overflow: hidden;
    }

    .loader-center {
        position: absolute;
        z-index: 1;
        background: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        inset: 0;
        // backdrop-filter: blur(5px);
        animation: loading-background 2s infinite;
    }

    &.loader_parent--hide {
        .loader-center {
            display: none;
        }
    }
}

.mat-mdc-select-value-text {
    color: var(--content-color) !important;
}

.loader-center {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 400ms all;
    animation: loading-spinner 2s infinite;
    cursor: wait;
}

@keyframes loading-spinner {

    0% {
        filter: blur(0px);
        letter-spacing: 0px;
    }

    50% {
        filter: blur(1px);
        letter-spacing: 2px;
        opacity: .5;
    }

    100% {
        filter: blur(0px);
        letter-spacing: 0px;
    }
}

@keyframes loading-background {

    0% {
        backdrop-filter: blur(5px);
        letter-spacing: 0px;
    }

    50% {
        backdrop-filter: blur(10px);
        letter-spacing: 2px;
    }

    100% {
        backdrop-filter: blur(5px);
        letter-spacing: 0px;
    }
}


// button

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family: var(--font-family) !important;

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: #fff !important;
    }
}


.snackbar_red .mdc-snackbar__surface {

    background-color: #F2F2F2 !important;

    .mdc-snackbar__label {
        color: #C22433 !important;
    }
}

.snackbar_green .mdc-snackbar__surface {
    background-color: #F2F2F2 !important;

    .mdc-snackbar__label {
        color: #396F20 !important;
    }
}

mat-snack-bar-container.mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family:  var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}




.hide-expansion-panel-body .mat-expansion-panel-body {
    display: none;
}

.payout_card {
    .mat-expansion-panel-body {
        padding: 0 15px 30px !important;
    }

    @media screen and (max-width: 991px) {
        .mdc-checkbox:hover .mdc-checkbox__ripple {
            opacity: 0 !important;
            display: none;
        }

        .mat-mdc-checkbox-ripple,
        .mdc-checkbox__ripple {
            opacity: 0 !important;
            display: none;
        }
    }

}


.sorting_header_button {
    cursor: pointer;
    user-select: none;
}

.sortingArrow {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-left: 4px;
    background: var(--select-icon) no-repeat center;
    background-size: contain;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 800ms;

    &.desc {
        transform: rotate(180deg);
    }
}

.admin_mat_date_range {
    &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
        border: 1px solid #ccc;
        padding-left: 10px !important;
        border-radius: 0.3125rem !important;
        overflow: hidden;
    }

    .mat-mdc-form-field-infix {
        background: #fff;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .date-clear-btn {
        margin-right: 11px;
        position: relative;
        top: 4px;
        cursor: pointer;
    }

}

.payouts_date_picker .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: center;

    .date-clear-btn {
        top: 0;
        margin-right: 15px;
        right: -10px;
        color: #7D7D7D;
        fill: #7D7D7D;
        position: relative;
        cursor: pointer;
        svg{
            --size:12px;
            height: var(--size);
            width: var(--size);
        }
    }

}

.mat-mdc-tab-labels {
    border-bottom: 1px solid #ccc;
}

.payouts_date_picker .mat-mdc-text-field-wrapper {
    border: 1px solid #DEDEDE !important;
}

.order_date_picker {
    .mat-mdc-form-field-infix {
        background: #f8f9fa;
    }
}

@media screen and (max-width:960px) {
    .admin_mat_date_range_mobile_fix {
        background: var(--light-color);
        border-radius: 4px;
        font-size: 12px !important;

        mat-label {
            font-size: 12px;
        }

        .mat-date-range-input-inner {
            font-size: 12px;
        }

        .mat-mdc-form-field-infix {
            background: transparent;
        }

        .mat-mdc-form-field-icon-suffix {
            display: flex;
            align-items: center;
        }

        .date-clear-btn {
            top: 0 !important;
        }
    }
}

.select_search_input {
    width: 100%;
    margin-top: -8px;
    position: absolute;
    z-index: 10;
    &+.mdc-list-item{
        margin-top: 55px;
    }
    .mat-mdc-form-field-infix {
        padding-left: 15px;
    }
}

.operating-hours {
    &-contet-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            border-radius: 2px;
            border: 1px solid #E1E1E1;
            background: #FFF;
        }

        .operating-input-grp {
            display: flex;
            align-items: center;
            opacity: .6;
            pointer-events: none;

        }

    }

    &-table {
        margin-block: 20px 30px;
    }

    &-heading {
        display: inline-flex;
        align-items: center;
        column-gap: 3px;
        font-family:  var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
        padding-left: 10px;

        p {
            font-size: 14px;
            font-weight: 300;
        }
    }

    &-opening-input,
    &-closing-input {
        margin-inline: 10px;
        min-width: 105px;

        input {
            width: 100%;
            border-radius: 4px;
            border: 1px solid #E1E1E1;
            background: #FFF;
            padding-inline: 5px;
        }
    }

    &-svg {
        display: flex;
    }

    &-day {
        min-width: 145px;
        font-family:  var(--font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    
}
.weekdays_list{
    padding: 0;
    list-style: none;
    max-width: 300px;
    li{
        display: flex;
        padding-block: 15px;
        justify-content: space-between;
        font-size: 14px;
        opacity: .4;
        &.active{
            opacity: 1;
            // .day_name{
            //     color: var(--primary-color);
            // }
        }
        .day_name{
            font-weight: 500;
        }
        .time {
            display: flex;
            align-items: center;
            .open_time:after {
                content: '';
                display: inline-block;
                width: 10px;
                position: relative;
                bottom: 4px;
                margin-inline: 5px;
                border-top: 1px solid currentColor;
            }
        }
        // &:not(:last-child){
        //     border-bottom: 1px solid #ccc;
        // }
    }
}


.info_wrapper{
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
        background: #f5f4f7;
        border-radius: 4px;
    }
    .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before{
        border-bottom: 0 !important;
    }
    .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input{
        color: #666;
        font-size: 16px;
    }
}

.primary-slide-toggle{
    .mdc-switch:enabled .mdc-switch__track::after{
        background: #9ddb7f !important;
    }
    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
        background: #60bb3c !important;
    }
}