.modal-wrap,
.modal_backdrop {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: rgba(0, 0, 0, 0.4);
}



.modal_backdrop {
    background: transparent;
}

.modal {

    &-main {
        border-radius: 11px;
        border: 3px solid #FFF;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(177, 197, 224, 0.10);
        max-width: 400px;
        padding: 10px 15px 30px 15px;
        margin: auto;
        position: relative;
        width: auto;
        top: 25%;
        z-index: 10;
    }

    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &-heading {
        text-align: center;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        flex: 0 0 auto;
        width: calc(100% + 36px);
        line-height: 1.5;
        border-bottom: 1px solid #D2D2D2;
        padding-block: 4.5px 12px;
        padding-inline: 27px;
        margin-inline: -18px;

    }

    &-text {
        text-align: center;
    }

    &-graphic {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px;
        padding: 20px 0px 25px 0px;
    }

    &-close-btn {
        border: none;
        cursor: pointer;
        position: absolute;
        right: 14px;
        top: 16px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/%3E%3C/svg%3E") no-repeat center;
        background-size: contain;
        text-indent: -99999px;
        padding: 0;
        width: 25px;
        height: 25px;
    }


    &-xl {
        .modal-main {
            max-width: 715px;
        }
    }

    &-wrap {
        overflow: auto;

        .customised-form {
            padding-top: 20px;
        }
    }
}

.vendor-listing-wrap {
    .modal {
        &-close-btn {
            right: 0;
        }
    }
}

.modal-wrap{
    
    opacity: 0;
    visibility: hidden;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 800ms;
    overflow: hidden;
    .modal-main {
        transform: translateY(110%);
    }
}

.modal-main {
    transform: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 800ms;
}

.showModal {
    opacity: 1;
    visibility: visible;
    // overflow: auto;
    .modal-main {
        transform: none;
    }
}

@media screen and (max-width: 767px) {
    .modal-wrap,
    .modal_backdrop {

        z-index: 9;
       
    } 
    .modal {
        &-main {
            z-index: 10;
            top: 14%;
             bottom: 11%;
        }
    }
}