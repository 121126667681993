*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f5f4f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #333;
}

body {
    background: var(--site-bg);
    min-height: calc(100vh + 1px);
}


a {
    text-decoration: none;
    display: inline-block;
    color: var(--primary-color);
    font-weight: 500;
}

.order_id_link {
    color: var(--primary-color);
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
    }
}

.vendor_id_link {
    color: var(--content-color);
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 800ms;

    &:hover {
        color: var(--primary-color);
        text-decoration-line: underline;
    }

    &.disabled {
        pointer-events: none;
    }
}

.tooltip_icon {
    background-image: var(--tooltip-icon);
    height: 14px;
    width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    overflow: hidden;
    position: relative;
    top: 2px;
    margin-left: 5px;
    cursor: pointer;
}

strong,
.strong,
b {
    font-weight: var(--font-weight--bold);
}

img {
    max-width: 100%;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.open-menu:not(.toggle) {
    overflow: hidden;
}