// pagination
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    .pagination_list, .select_box_list{
        border: 0;
        width: auto;
        ul{
            display: flex;
        }
    }
    li {
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 14px;
        cursor: pointer;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 800ms;
        &:hover{
            color: var(--primary-color);
            border-color: var(--primary-color);
        }
        &.active{
            color: var(--primary-color);
            border-color: var(--primary-color);
            font-weight: 500;
            pointer-events: none;
        }
        &.innner_pages{
            border: 0;
            pointer-events: none;
            cursor: none;
        }
        &.disabled{
            pointer-events: none;
            opacity: 0.5;
            filter: grayscale(1);
        }
        svg {
            height: 14px;
            width: 14px;
        }

        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.pagination_wrapper {
    display: flex;
    justify-content: space-between;
    padding-inline: 30px;
    align-items: center;

    margin-top: 15px;

    .data_info {
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 0;
    }
}

.pagination_select_box{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 14px;
    color: var(--content-color);
}

.pagination_separator{
    margin-inline: 10px;
    background: #d4d4d4;
    width: 7px;
    height: 7px;
    display: inline-block;
    text-indent: -99px;
    overflow: hidden;
    border-radius: 30px;
    position: relative;
    top: -1px;
}

@media screen and (max-width:767px){
    .pagination_wrapper{
        flex-direction: column;
         .data_info{
            margin-bottom: 10px;
        }
    }
}