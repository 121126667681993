.mobile_filter_wrapper {
    .icon {
        display: none;
    }
    .mobile-close-icon {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .mobile_filter_wrapper {
        .icon {
            display: flex;
            background: #fff;
            border-radius: 30px;
            padding: 3px 10px;
            justify-content: center;
            align-items: center;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        .backdrop {
            inset: 0;
            position: fixed;
            background: rgba(0, 0, 0, 0.3);
            transition: cubic-bezier(0.075, 0.82, 0.165, 1) 800ms all;
            opacity: 0;
            visibility: hidden;
            cursor: none;
            z-index: 7;
        }

        .filter_body {
            opacity: 0;
            visibility: hidden;
            cursor: none;
            position: fixed;
            bottom: 0px;
            left: 0;
            background: #fff;
            width: 100%;
            padding: 15px;
            align-items: start;
            justify-content: center;
            max-height: calc(100vh - 70px);
            overflow: auto;
            border-radius: 20px 20px 0 0;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1) 800ms all;
            z-index: 999;
            filter: blur(5px);
        }

        &.openFilters {
            .backdrop {
                opacity: 1;
                visibility: visible;
                cursor: pointer;
            }

            .filter_body {
                display: flex;
                opacity: 1;
                visibility: visible;
                filter: blur(0);
            }
        }

    }
    .mobile_filter_wrapper {
        &.openFilters {
            .filter_body {
                
                bottom: 69px;
                
            }
        }
    }
    // for order listing
    .filter_header_wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-right: 10px;
        margin-top: -5px;
    }
}